export const INVITE_COLLABORATOR = 'INVITE_COLLABORATOR';
export const GUEST_INVITED = 'GUEST_INVITED';
export const INVITE_ACCEPTED = 'INVITE_ACCEPTED';
export const COLLABORATOR_GET = 'COLLABORATOR_GET';
export const COLLABORATOR_GET_ASYNC = 'COLLABORATOR_GET_ASYNC';
export const REMOVE_COLLABORATOR = 'REMOVE_COLLABORATOR';
export const REMOVE_COLLABORATOR_NOREGISTER = 'REMOVE_COLLABORATOR_NOREGISTER';
export const INVITE_ACCEPTED_ASYNC = 'INVITE_ACCEPTED_ASYNC';
export const CLEAR_COLLABORATORS = 'CLEAR_COLLABORATORS';
export const UPDATE_COLLABORATOR = 'UPDATE_COLLABORATOR';
export const UPDATE_COLLABORATOR_ASYNC = 'UPDATE_COLLABORATOR_ASYNC';


