export const LOG_STORAGE_USAGE = "LOG_STORAGE_USAGE";
export const LOG_STORAGE_USAGE_ASYNC = "LOG_STORAGE_USAGE_ASYNC";

export const GET_STORAGE_LOGS = "GET_STORAGE_LOGS";
export const GET_STORAGE_LOGS_ASYNC = "GET_STORAGE_LOGS_ASYNC";

export const GET_STORAGE_LOG = "GET_STORAGE_LOG";

export const CLEAR_STORAGE_LOGS_DATA = "CLEAR_STORAGE_LOGS_DATA";

export const SHOW_STORAGE_POPUP = 'SHOW_POPUP';
export const HIDE_STORAGE_POPUP = 'HIDE_POPUP';